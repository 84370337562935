/**
* Created by litian on 2019-04-02.
*/
<style scoped lang="less">
    .pop {
        .ivu-row, .ivu-row-flex {
            margin-bottom: 20px;
        }
    }
    .deviceLabel {
        margin-left: 10px;
        font: bold 14px Georgia;
    }

    .line {
        background-color: #E5E5E5;
        height: 1px;
        margin-top: 5px;
    }

    .pop-width {
        width: 200px;
    }

    .col-label {
        display: inline-block;
    }
</style>

<template>
    <div>
        <!--设备详情-->
        <Modal width="650" v-model="showDeviceDtlModal" title="设备详情" @on-cancel="resetFields('dtlPop')">
            <Form ref="dtlPop" :model="deviceDtl" :label-width="90">
                <div class="deviceLabel">设备信息</div>
                <div class="line"></div>
                <Row style="margin-top: 10px;">
                    <Col span="12">
                        <Form-item label="设备IMEI" prop="device">
                            <Input class="pop-width" v-model="deviceDtl.device" readonly/>
                        </Form-item>
                    </Col>
                    <Col span="12">
                        <Form-item label="创建时间" prop="createTime">
                            <Input class="pop-width" v-model="deviceDtl.createTime" readonly/>
                        </Form-item>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                        <Form-item label="设备型号" prop="deviceType">
                            <Input class="pop-width" v-model="deviceDtl.productModel" readonly/>
                        </Form-item>
                    </Col>
                    <Col span="12">
                        <Form-item label="到期时间" prop="expireTime">
                            <Input class="pop-width" v-model="deviceDtl.expireTime" readonly/>
                        </Form-item>
                    </Col>
                </Row>
                <div class="deviceLabel">SIM卡信息</div>
                <div class="line"></div>
                <Row style="margin-top: 10px;">
                    <Col span="12">
                        <Form-item label="设备SIM卡号" prop="sim">
                            <Input class="pop-width" v-model="deviceDtl.sim" readonly/>
                        </Form-item>
                    </Col>
                    <Col span="12">
                        <Form-item label="ICCID" prop="iccid">
                            <Input class="pop-width" v-model="deviceDtl.iccid" readonly/>
                        </Form-item>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                        <Form-item label="激活日期" >
                            {{deviceDtl.activationDate}}
                        </Form-item>
                    </Col>
                    <Col span="12">
                        <Form-item label="服务期止" >
                            {{deviceDtl.validDate}}
                        </Form-item>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                        <Form-item label="已使用流量" >
                            {{deviceDtl.used?(deviceDtl.used/1024).toFixed(2):'0'}}MB
                        </Form-item>
                    </Col>
                    <Col span="12">
                        <Form-item label="剩余流量" >
                            {{deviceDtl.allowance?(deviceDtl.allowance/1024).toFixed(2):'0'}}MB
                        </Form-item>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="primary" size="large" @click="resetFields('dtlPop')">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import {formateDate} from '../../libs/util';

    export default {
        data() {
            return {
                showDeviceDtlModal: false,
                deviceDtl: {},
            }
        },
        methods: {
            resetFields(value) { // 重置数据
                this.showDeviceDtlModal = false;
                this.showRateModal = false;
                this.addLoading = false;
                this.$refs[value].resetFields();
            },
            showDeviceDetail(row) {
                this.showDeviceDtlModal = true;
                this.$ajax.post('/xhk_web', {
                    service: 'device/fetch_one',
                    device: row.device
                }, res => {
                    if (res.status === 200) {
                        let deviceDtl = {
                            ...res.body,
                        };

                        deviceDtl.createTime = formateDate(deviceDtl.created * 1000);
                        deviceDtl.expireTime=formateDate(deviceDtl.expireTime * 1000);
                        this.$set(this, 'deviceDtl', deviceDtl);
                    }
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
        },
        mounted() {
            //console.log(this.$el);
        }
    };
</script>
