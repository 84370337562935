/**
* Created by litian on 2019-04-02.
*/
<style scoped lang="less">
    .pop {
        overflow-y: auto;
        max-height: 400px;

        .ivu-row, .ivu-row-flex {
            margin-bottom: 10px;
        }
    }

    .modal-full-line {
        height: 1px;
        width: 100%;
        background: #F6F6F6;
        margin: 20px 0;
    }

    .vertical-line {
        height: 14px;
        width: 1px;
        background: #999999;
        margin: 0 20px;
        display: inline-block;
        vertical-align: middle;
    }

    .reported-tip {
        margin-left: 10px;
        color: #999;
    }
</style>

<template>
    <div>
        <!--定位模式设置-->
        <Modal width="750" v-model="showRateModal" :title="rateModalTitle" @on-cancel="setDefaultSetting">
            <div class="pop">
                <!--<div>指令生效需等待设备回传后确定</div>-->
                <Row v-if="rateOnce">
                    <i-col span="4">设备平台：</i-col>
                    <i-col span="8">
                        {{deviceRate.platformName || ''}}
                    </i-col>
                    <i-col span="4">设备号：</i-col>
                    <i-col span="8">
                        {{deviceRate.device}}
                    </i-col>
                </Row>
                <template v-else>
                    <Row>
                        <i-col span="4">设备数量：</i-col>
                        <i-col span="8">
                            {{deviceRate.devices.length}}
                        </i-col>
                    </Row>
                    <Row type="flex" justify="start" align="top" style="flex-wrap:nowrap;">
                        <i-col span="4">
                            <span class="col-label">设备号：</span>
                        </i-col>
                        <i-col span="20">
                            <Row type="flex" justify="start" align="top">
                                <i-col v-for="device in showDevices" :key="device"
                                       style="margin-right: 5px; margin-bottom: 5px;">
                                    <Input readonly :value="device" icon="md-close"
                                           @on-click="outOfDeviceRate(device)"/>
                                </i-col>
                            </Row>
                            <Button v-if="!isShowAllDevices" type="text"
                                    size="small"
                                    icon="md-arrow-round-down"
                                    style="color: #4E5FF7;"
                                    @click="toggleShowDevices">全部设备
                            </Button>
                            <Button v-else type="text"
                                    size="small"
                                    icon="md-arrow-round-up"
                                    style="color: #4E5FF7;"
                                    @click="toggleShowDevices">部分设备
                            </Button>
                        </i-col>
                    </Row>
                </template>
                <div class="modal-full-line"></div>
                <template v-if="showTemperature">
                    <Row>
                        <i-col span="4">温湿度监控：</i-col>
                        <i-col span="20">
                            <i-switch size="small" v-model="deviceRate.humitureStatus" :true-value="1"
                                      :false-value="0"/>
                        </i-col>
                    </Row>
                    <template v-if="deviceRate.humitureStatus == 1">
                        <Row>
                            <i-col span="4">&nbsp;</i-col>
                            <i-col span="20">
                                温湿度采集频率：
                                <InputNumber
                                    :active-change="false"
                                    v-model="deviceRate.humitureRate"
                                    :precision="0"
                                    :max="15" :min="5"></InputNumber>
                                <span style="margin-left: 5px;">分钟/次</span>
                                <span class="reported-tip">默认5分钟，设置范围：5-15分钟</span>
                            </i-col>
                        </Row>
                        <Row>
                            <i-col span="4">&nbsp;</i-col>
                            <i-col span="20">
                                温度报警区间：
                                <InputNumber
                                        :active-change="false"
                                        v-model="deviceRate.temperatureMin"
                                        :precision="0"
                                        :max="70" :min="-40"></InputNumber> ℃ -
                                <InputNumber
                                        :active-change="false"
                                        v-model="deviceRate.temperatureMax"
                                        :precision="0"
                                        :max="70" :min="-40"></InputNumber> ℃
                            </i-col>
                        </Row>
                        <Row>
                            <i-col span="4">&nbsp;</i-col>
                            <i-col span="20">
                                湿度报警区间：
                                <InputNumber
                                        :active-change="false"
                                        v-model="deviceRate.humidityMin"
                                        :precision="0"
                                        :max="100" :min="0"></InputNumber> RH -
                                <InputNumber
                                        :active-change="false"
                                        v-model="deviceRate.humidityMax"
                                        :precision="0"
                                        :max="100" :min="0"></InputNumber> RH
                            </i-col>
                        </Row>
                    </template>
                    <div class="modal-full-line"></div>
                </template>
                <template v-if="showLightAlarm">
                    <Row>
                        <i-col span="4">光感报警：</i-col>
                        <i-col span="20">
                            <i-switch size="small" v-model="deviceRate.lightSensorStatus" :true-value="1"
                                      :false-value="0"/>
                        </i-col>
                    </Row>
                    <div class="modal-full-line"></div>
                </template>
                <template v-if="showReportedRate">
                    <Row type="flex" justify="center" align="top">
                        <i-col span="4" style="line-height: 30px;">定位模式：</i-col>
                        <i-col span="20">
                            <RadioGroup v-model="deviceRate.mode" @on-change="changeRateMode" style="width: 100%;" vertical>
                                <template v-if="showTimeSend">
                                    <Radio :label="0">
                                        <span>定时上报</span>
                                    </Radio>
                                    <template v-if="deviceRate.mode === 0">
                                        <Row type="flex" align="middle">
                                            <i-col span="4">定位周期</i-col>
                                            <i-col>
                                                <InputNumber
                                                        :active-change="false"
                                                        v-model="deviceRate.reportedRate"
                                                        :precision="0"
                                                        :max="1440" :min="5"></InputNumber>
                                                <span style="margin-left: 5px;">分钟/次</span>
                                                <span class="reported-tip">默认15分钟，设置范围：5-1440分钟</span>
                                            </i-col>
                                        </Row>
                                        <Row type="flex" align="middle" v-if="showStaticTimeReport">
                                            <i-col span="4">静止后通讯周期</i-col>
                                            <InputNumber :active-change="false"
                                                         v-model="staticTimeByMode0"
                                                         :precision="0"
                                                         :max="1440" :min="30"></InputNumber>
                                            <span style="margin-left: 5px;">分钟/次</span>
                                            <span class="reported-tip">默认180分钟，设置范围：30-1440分钟</span>
                                        </Row>
                                    </template>
                                </template>
                                <Radio :label="1">
                                    <span>实时追踪</span>
                                </Radio>
                                <template v-if="deviceRate.mode === 1">
                                    <Row type="flex" align="middle">
                                        <i-col span="4">定位周期</i-col>
                                        <InputNumber
                                                :active-change="false"
                                                v-model="deviceRate.reportedRate"
                                                :precision="0"
                                                :max="300" :min="10"></InputNumber>
                                        <span style="margin-left: 5px;">秒/次</span>
                                        <span class="reported-tip">默认45秒，设置范围：10-300秒</span>
                                    </Row>
                                    <Row type="flex" align="middle" v-if="showStaticTimeReport">
                                        <i-col span="4">静止后通讯周期</i-col>
                                        <InputNumber
                                                :active-change="false"
                                                v-model="staticTimeByMode1"
                                                :precision="0"
                                                :max="360" :min="30"></InputNumber>
                                        <span style="margin-left: 5px;">分钟/次</span>
                                        <span class="reported-tip">默认60分钟，设置范围：30-360分钟</span>
                                    </Row>
                                </template>
                            </RadioGroup>
                        </i-col>
                    </Row>
                    <div class="modal-full-line"></div>
                </template>
                <template v-if="showLocateType">
                    <Row>
                        <i-col span="4">定位类型：</i-col>
                        <i-col span="20">
                            <Checkbox v-model="deviceRate.gpsSwitch" :true-value="1" :false-value="0">GPS</Checkbox>
                            <Checkbox v-model="deviceRate.wifiSwitch" :true-value="1" :false-value="0">WiFi</Checkbox>
                        </i-col>
                    </Row>
                    <div class="modal-full-line"></div>
                </template>
                <template v-if="showSettingCmd">
                    <Row>
                        <i-col span="4">指令：</i-col>
                        <i-col span="20">
                            <Input v-model="cmdValue" style="width: 80%;" placeholder="请输入指令" />
                        </i-col>
                    </Row>
                    <div class="modal-full-line"></div>
                </template>
            </div>
            <Row slot="footer" type="flex" justify="center" align="middle">
                <Button v-if="!!supportCMDList" type="primary" size="large" :loading="addLoading"
                        @click="delaySubmit()">发送指令
                </Button>
                <Button size="large" @click="restart">重启设备</Button>
                <Button size="large" @click="close">关闭</Button>
            </Row>
        </Modal>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {checkValueContainDefault} from '../../libs/util';
    //型号支持的指令类型
    const deviceSupportCMDMap = {
        'X1': ['timeSendSwitch','changeReportedRate'],
        'X2': ['timeSendSwitch','changeReportedRate'],
        'X2-15': ['timeSendSwitch','changeReportedRate'],
        'X3': [
            'timeSendSwitch',
            'changeReportedRate',
            'temperatureAlarmSwitch',
            'lightAlarmSwitch',
            'gpsSwitch',
            'wifiSwitch',
            'statictimeReportedRate',
            'settingCmd'
        ],
        'K3':[
            'changeReportedRate',
            'statictimeReportedRate',
        ],
    };
    const DEFAULT_MODE1_RATE = 45; //实时追踪间隔为45秒
    const DEFAULT_MODE0_RATE = 15; //定时上报间隔为15分钟
    const STATIC_TIME_MODE_0 = 180; //定时上报静止后通讯间隔180分钟
    const STATIC_TIME_MODE_1 = 60; //实时追踪静止后通讯间隔60分钟
    export default {
        data() {
            return {
                showRateModal: false,
                rateOnce: true,
                addLoading: false,
                deviceRate: {},
                showDevices: [],
                supportCMDList: [],
                isShowAllDevices: false,
                staticTimeByMode0: STATIC_TIME_MODE_0,
                staticTimeByMode1: STATIC_TIME_MODE_1,
                cmdValue:''
            }
        },
        computed: {
            rateModalTitle() {
                return `指令设置(${this.rateOnce ? "单个设备" : "批量设备"})`
            },
            showTimeSend() {
                return this.checkSupport('timeSendSwitch');
            },
            showTemperature() {
                return this.checkSupport('temperatureAlarmSwitch');
            },
            showLightAlarm() {
                return this.checkSupport('lightAlarmSwitch');
            },
            showReportedRate() {
                return this.checkSupport('changeReportedRate');
            },
            showLocateType() {
                return this.checkSupport(['gpsSwitch', 'wifiSwitch']);
            },
            showStaticTimeReport() {
                return this.checkSupport(['statictimeReportedRate']);
            },
            showSettingCmd(){
                return this.checkSupport(['settingCmd']);
            },
            productModel() {
                return this.deviceRate.productModel || 'X3'; //批量时按照X3版本显示指令
            }
        },
        methods: {
            checkSupport(type) {
                let {supportCMDList} = this;
                let types = [];
                if (typeof type === 'string') {
                    types.push(type);
                } else {
                    types = type;
                }
                if (supportCMDList) {
                    return _.reduce(types, (m, t) => {
                        if (m) {
                            return m;
                        } else {
                            return _.indexOf(supportCMDList, t) > -1;
                        }
                    }, false);
                } else {
                    return false
                }
            },
            setCommand(devices,command){
                this.$ajax.post('/device/reset', {
                    // service: 'device/command/send_command',
                    device: devices,
                    // commandParams: command,
                }, res => {
                    this.addLoading = false;
                    if (res.code === 200) {
                        this.showRateModal = false;
                        this.setDefaultSetting();
                        // this.$Message.success(res.message !== 'SUCCESS' ? res.message : '设置成功');
                        this.$Message.success(res.message !== 'SUCCESS' ? '重启成功' : '重启成功');
                    } else {
                        this.$Message.error(res.message || '服务器异常，请重试');
                    }
                }, err => {
                    this.addLoading = false;
                    this.$Message.error(err.message || '服务器异常，请重试');
                })
            },
            getActiveDevices(){
                return this.rateOnce ? this.deviceRate.device : this.deviceRate.devices.join(',');
            },
            restart(){
                let devices = this.getActiveDevices();
                this.setCommand(devices,[{"cmd": "reset"}]);
            },
            delaySubmit(fnName = 'change_reported_rate', time = 200) {
                this.addLoading = true;
                if (fnName && typeof this[fnName] === 'function') {
                    setTimeout(() => {
                        this[fnName]();
                    }, time);
                }
            },
            change_reported_rate() {
                let {deviceRate} = this;

                this.addLoading = true;
                let commands = [];
                let {mode, reportedRate} = this.deviceRate;
                //定位模式
                if (this.showReportedRate) {
                    if (reportedRate == null) {
                        this.$Message.error('请输入定位周期');
                        this.addLoading = false;
                        return;
                    }
                    commands.push(
                        {
                            cmd: "changeReportedRate",
                            params: {
                                mode: mode,
                                reportedRate: reportedRate
                            }
                        }
                    );
                }
                //静止时通讯周期
                if (this.showStaticTimeReport) {
                    let staticTime = this['staticTimeByMode' + mode];
                    if (staticTime == null) {
                        this.$Message.error('请输入静止后通讯周期');
                        this.addLoading = false;
                        return;
                    }
                    commands.push(
                        {
                            cmd: "statictimeReportedRate",
                            params: {
                                mode: mode,
                                reportedRate: staticTime
                            }
                        }
                    );
                }
                //光感
                if (this.showLightAlarm) {
                    //0:开启 1:关闭 【光感】
                    let {lightSensorStatus} = deviceRate;
                    commands.push({
                        cmd: "lightAlarmSwitch",
                        params: {
                            isOpen: checkValueContainDefault(lightSensorStatus, 1),
                        }
                    });
                }
                //温湿度
                if (this.showTemperature) {
                    //0:开启 1:关闭 【温湿度】
                    let {humitureStatus,humitureRate} = deviceRate;
                    let humitureAlarmData = deviceRate.temperatureMin + ',' + deviceRate.temperatureMax + ',' + deviceRate.humidityMin + ',' + deviceRate.humidityMax;
                        commands.push({
                        cmd: "temperatureAlarmSwitch",
                        params: {
                            isOpen: checkValueContainDefault(humitureStatus, 1),
                        }
                    },{
                        cmd: "humitureRate",
                        params: {
                            reportedRate: humitureRate*60
                        }
                    },{
                        cmd: "humitureAlarmData",
                        params: {
                            data: humitureAlarmData
                        }
                    });
                }
                //定位类型 默认开启
                if (this.showLocateType) {
                    //0:关闭 1:开启【定位类型】
                    ['gpsSwitch', 'wifiSwitch'].forEach(key => {
                        let val = deviceRate[key];
                        commands.push({
                            cmd: key,
                            params: {
                                isOpen: val,
                            }
                        });
                    });
                }
                //指令
                if(this.showSettingCmd && this.cmdValue){
                    let {cmdValue} = this;
                    commands.push({
                        cmd: "settingCmd",
                        params: {
                            cmdValue: cmdValue,
                        }
                    });
                }
                let devices = this.getActiveDevices();
                this.setCommand(devices,commands);
            },
            outOfDeviceRate(device) {
                if (this.deviceRate.devices && this.deviceRate.devices.length > 1) {
                    let i = this.deviceRate.devices.indexOf(device);
                    this.deviceRate.devices.splice(i, 1);
                    this.setShowDevices();
                } else {
                    this.deviceRate.devices = [device];
                    this.$Message.error('至少保留一个设备');
                }
            },
            setShowDevices() {
                let {deviceRate, isShowAllDevices} = this;
                if (deviceRate.devices) {
                    if (isShowAllDevices || deviceRate.devices.length < 6) {
                        this.showDevices = [...deviceRate.devices];
                    } else {
                        this.showDevices = _.slice(deviceRate.devices, 0, 6);
                    }
                } else {
                    this.showDevices = [];
                }
            },
            toggleShowDevices() {
                this.isShowAllDevices = !this.isShowAllDevices;
                this.setShowDevices();
            },
            //修改定位模式
            changeRateMode(mode) {
                if (mode === 0) {
                    this.deviceRate.reportedRate = DEFAULT_MODE0_RATE
                } else {
                    this.deviceRate.reportedRate = DEFAULT_MODE1_RATE
                }
            },
            //设备支持的指令合集
            getSupportCMDList(models) {
                if (!models) return null;
                if (typeof models === 'string') {
                    return deviceSupportCMDMap[models] || null;
                } else {
                    let list = models.map(model => deviceSupportCMDMap[model]);
                    return _.uniq(_.concat(...list));
                }
            },
            //指令设置
            startReportedRate(row) {
                this.deviceRate = {...row};
                //mode: 0, reportedRate: 15,
                if (!this.deviceRate.reportedRate) {
                    this.deviceRate.mode = 0;
                    this.deviceRate.reportedRate = DEFAULT_MODE0_RATE;
                }
                if (!this.deviceRate.humitureRate) {
                    // 默认值 -40,70,0,100
                    this.deviceRate.temperatureAlarmSwitch = 0;
                    this.deviceRate.humitureRate = 5;
                    this.deviceRate.temperatureMin = -40;
                    this.deviceRate.temperatureMax = 70;
                    this.deviceRate.humidityMin = 0;
                    this.deviceRate.humidityMax = 100;
                }else {
                    this.deviceRate.humitureRate = this.deviceRate.humitureRate/60;
                    let humitureAlarmDataArry = this.deviceRate.humitureAlarmData.split(',');
                    this.deviceRate.temperatureMin = Number(humitureAlarmDataArry[0]);
                    this.deviceRate.temperatureMax = Number(humitureAlarmDataArry[1]);
                    this.deviceRate.humidityMin = Number(humitureAlarmDataArry[2]);
                    this.deviceRate.humidityMax = Number(humitureAlarmDataArry[3]);
                }

                //默认开启gps和wifi
                this.deviceRate.gpsSwitch = checkValueContainDefault(row.gpsSwitch, 1);
                this.deviceRate.wifiSwitch = checkValueContainDefault(row.wifiSwitch, 1);

                //静止后通讯间隔
                this.staticTimeByMode0 = checkValueContainDefault(row.staticTimeByMode0, STATIC_TIME_MODE_0);
                this.staticTimeByMode1 = checkValueContainDefault(row.staticTimeByMode1, STATIC_TIME_MODE_1);
                //指令
                this.cmdValue = '';

                this.supportCMDList = this.getSupportCMDList(row.productModel);
                this.rateOnce = true;
                this.showRateModal = true;
            },

            //批量指令设置
            startBatchReportedRate(selectionList) {
                if (selectionList && selectionList.length > 0) {
                    let devices = selectionList.map(item => item.device);
                    let modelSet = new Set(selectionList.map(item => item.productModel));
                    let productModelList = [...modelSet];
                    this.supportCMDList = this.getSupportCMDList(productModelList);
                    //console.log('supportCMDList:',this.supportCMDList);
                    this.deviceRate = {
                        devices: devices,
                        mode: 0,
                        reportedRate: DEFAULT_MODE0_RATE,
                        lightSensorStatus: 0,
                        humitureStatus: 0,
                        gpsSwitch: 0,
                        wifiSwitch: 0
                    };
                    this.staticTimeByMode0 = checkValueContainDefault(null, STATIC_TIME_MODE_0);
                    this.staticTimeByMode1 = checkValueContainDefault(null, STATIC_TIME_MODE_1);

                    this.cmdValue = '';

                    this.setShowDevices();
                    this.rateOnce = false;
                    this.showRateModal = true;
                } else {
                    this.$Message.error('请选择要操作的设备');
                }
            },
            setDefaultSetting() {
                this.isShowAllDevices = false;
            },
            close() {
                this.showRateModal = false;
            }
        },
        mounted() {
            //console.log(this.$el);
        }
    };
</script>
